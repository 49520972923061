<template>
  <Portlet 
    title="Central Device Management (Gekko)" 
    sub-title="Unconfigured Devices"
    class="unconfiguredDevices"
    icon="tablet-alt"
  >
    <UnconfiguredDevicesDisplay />
  </Portlet>
</template>

<script>
export default {
  name: "UnconfiguredDevices",
  components: {
    UnconfiguredDevicesDisplay: () => import('@/components/Device/UnconfiguredDevicesDisplay.vue')
  },
  metaInfo () {
    return {
      title: 'Central Device Management'
    }
  }
}
</script>
